import type * as prismic from '@prismicio/client'

type MetaFields = {
  meta_description?: prismic.KeyTextField
  meta_title?: prismic.KeyTextField
  meta_image?: prismic.ImageField
}

export function usePageMetadata(data?: MetaFields) {
  const i18n = useI18n()

  const META_TITLE = data?.meta_title ? `${data?.meta_title} | elvah` : 'elvah'
  const META_DESCRIPTION = `${data?.meta_description || ''}`
  const META_IMAGE = data?.meta_image || undefined

  useSeoMeta({
    title: META_TITLE,
    ogTitle: META_TITLE,
    twitterTitle: META_TITLE,
    description: META_DESCRIPTION,
    ogDescription: META_DESCRIPTION,
    twitterDescription: META_DESCRIPTION,
    ogImage: {
      url: META_IMAGE?.url || '',
      width: META_IMAGE?.dimensions?.width,
      height: META_IMAGE?.dimensions?.height,
    },
    ogUrl: 'https://elvah.de',
    ogLocale: i18n.locale.value,
    twitterCard: 'summary_large_image',
  })
}
